import React from 'react';
import styles from './index.module.scss';
import { handleRouteChangesOnClick } from '@/utils/handleRouteChangesOnClick';

export const FooterStorePolicies = (props) => {
  return !props.storePolicies || props.storePolicies.length == 0 ? null : (
    <div className={`${styles.bottom_section} flex flex-column items-center`}>
      <div className={`${styles.store_policies} flex justify-around w-100 f6`}>
        {props?.storePolicies.map((page) => {
          return (
            <p
              name={`page${page.page_type}`}
              className={styles.footer_nav}
              onClick={() =>
                handleRouteChangesOnClick(props?.path, {
                  page_type: page.page_type,
                })
              }
              key={page.id}
            >
              {page.page_name}
            </p>
          );
        })}
      </div>
    </div>
  );
};
