import styled from 'styled-components';

export const PointingArrow = styled.div`
  height: 0;
  width: 20px;
  margin-left: ${props => props.ml || 0}rem;
  transform: rotateZ(180deg);
  margin-top: ${props => props.mt || 0}rem;
  box-shadow: 0px -6px 12px 2px rgb(0 0 0 / 30%);
  ${props =>
    props?.translateX &&
    props?.translateY &&
    `transform : translate(${props?.translateX}rem , ${props?.translateY}rem) rotate(180deg)`};

  &:before {
    content: '';
    border-top: 10px solid white;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
`;
