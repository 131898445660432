import useClosePopupFromOutsideClick from '@/utils/useClosePopupFromOutsideClick';
import React from 'react';
import { useRef } from 'react';
import { PointingArrow } from './ReasonModal.styles';

function ReasonModalHOC(props) {
  const elementReferenceForOutsideClick = useRef();
  const closePopupForParticularId = {
    id: props.id,
    toggleStatus: false,
  };
  useClosePopupFromOutsideClick(
    elementReferenceForOutsideClick,
    props.toggleAdReasonModal,
    closePopupForParticularId
  );

  return (
    <>
      <PointingArrow
        ml={props.ml}
        translateX={props.translateX}
        translateY={props.translateY}
        mt={props.mt}
      />
      <div
        style={{ zIndex: 1 }}
        ref={elementReferenceForOutsideClick}
        className={props.class}
      >
        <props.reasonModal />
      </div>
    </>
  );
}

export default ReasonModalHOC;
