import { removeDsAdsRouteLink } from '@/utils/removeDsAdsRouteLink';
import { deviceWidth } from '@/utils/deviceWidth';
import { InfoIcon } from '@/assets/svgExports/InfoIcon';
import { FlagIcon } from '@/assets/svgExports/FlagIcon';
import Image from 'next/image';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import 'url-search-params-polyfill';
import {
  CANCELATION_AND_REFUND_POLICY_URL,
  DS_TERMS_AND_CONDITIONS_URL,
  MAX_MOBILE_WIDTH,
} from '../../utils/constants';
import MobileReasonModal from '../DesignSystem/DigitalShowroomAds/MobileReasonModal';
import ReasonModalHOC from '../DesignSystem/DigitalShowroomAds/ReasonModalHOC';
import { AdContainer } from '../DesignSystem/MiddleBody/MiddleBody6/Hybrid.styles';
import checkIfThemePreview from '@/utils/checkIfThemePreview';

const BrandFooter = (props) => {
  const storeData = useSelector((state) => state.storeReducer.store);
  const [showAdInfo, setShowAdInfo] = useState(false);
  const shouldHideDSLogo =
    storeData?.theme?.components?.Footer?.[0]?.config?.hide_ds_label;
  const isPreview = checkIfThemePreview();

  const onPlayStoreIconClick = () => {
    window.open('https://download.digitalshowroom.app/download-cfe', '_blank');
  };
  function toggleAdReasonModal() {
    setShowAdInfo(!showAdInfo);
  }

  function renderMobileAds() {
    return (
      !isPreview && (
        <>
          {deviceWidth <= MAX_MOBILE_WIDTH &&
            storeData.store_info?.non_premium_ad_url_mobile &&
            !storeData?.theme?.is_demo && (
              <AdContainer paddingTop={30} paddingBottom={30} backgroundColor={'#000000'}>
                <div className="flex flex-column digital-showroom__ads w-100 ph3">
                  <div
                    className="flex w-100 bg-black-90 mb6px"
                    onClick={() => setShowAdInfo(true)}
                  >
                    <div className="flex f12px o-50 mb6px white-90">
                      Why I am seeing this
                    </div>
                    <div className="digital-showroom__ads_footermt_-2px ml6px">
                      <InfoIcon size={16} secondaryColor={'#838383'} />
                    </div>
                  </div>
                  {showAdInfo && (
                    <ReasonModalHOC
                      toggleAdReasonModal={toggleAdReasonModal}
                      reasonModal={MobileReasonModal}
                      class={'mobile-reason-modal'}
                      ml={-1.21}
                      mt={1.45}
                      translateX={9.35}
                      translateY={-2.25}
                    />
                  )}
                  <div
                    className="flex gif-height pointer items-start"
                    onClick={removeDsAdsRouteLink}
                  >
                    <Image
                      src={storeData.store_info?.non_premium_ad_url_mobile}
                      width={630}
                      height={187}
                    ></Image>
                  </div>
                </div>
              </AdContainer>
            )}
        </>
      )
    );
  }

  return (
    <div className="brand-footer">
      <div className="brand-footer__wrapper text-links">
        <span className="brand-footer__linkText">
          <a
            className="brand-footer__linkText--href"
            href={DS_TERMS_AND_CONDITIONS_URL}
            target="_blank"
            rel="noreferrer"
          >
            View Terms & Conditions
          </a>
        </span>
        <span className="brand-footer__linkText">
          <a
            className="brand-footer__linkText--href"
            href={CANCELATION_AND_REFUND_POLICY_URL}
            target="_blank"
            rel="noreferrer"
          >
            Refunds & Cancellation
          </a>
        </span>
        <span className="brand-footer__linkText flex flex-row">
          <a
            className="brand-footer__linkText--href flex"
            href="https://forms.gle/E86rmFAD2GP9rL9f7"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex pt2px mr6px">
              <FlagIcon />
            </div>
            Report Store
          </a>
        </span>
      </div>
      {renderMobileAds()}
      {!shouldHideDSLogo && (
        <div
          className="brand-footer__wrapper bg-pure-black-color"
          onClick={onPlayStoreIconClick}
        >
          <span className="brand-footer__fadeText">Online store created using</span>
          <span className="brand-footer__BrandIcon">
            <img
              src="/assets/images/bottom-app-logo.png"
              loading="lazy"
              alt="Brand App Logo"
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default BrandFooter;
