import { useEffect } from 'react';

function useClosePopupFromOutsideClick(
  referenceToDomElement,
  toggleClose,
  props
) {
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = e => {
    if (!e.composedPath()?.includes(referenceToDomElement.current)) {
      e.stopPropagation();
      toggleClose(props?.id, props?.toggleStatus);
    }
  };
}

export default useClosePopupFromOutsideClick;
