import React from 'react';
import BrandFooter from '@/components/BrandFooter';
import FooterStoreInfo from '@/components/FooterStoreInfo';
import { useSSRSelector } from '@/redux/ssrStore';

const Footer1 = (props) => {
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  return (
    <footer>
      <FooterStoreInfo store={storeData} props={props} />
      <BrandFooter />
    </footer>
  );
};

export default Footer1;
