import styled from 'styled-components';


function slideInAnimate() {
  return (
    `
    animation-name: slide-in;
    animation-duration: 0.5s;
    transform : translateX(50px);

    @keyframes slide-in {
      from {
          transform : translateX(0px);
      }
      to {
          transform : translateX(50px);
      }
    }
    `
  );
}

function slideOutAnimate(trasnlationDist) {
  return (
    `
    animation-name: slide-out;
    animation-duration: 0.50s;

    @keyframes slide-out {
      from {
          transform : translateX(50px);
      }
      to {
        transform : translateX(-${trasnlationDist - 50}px);
      }
    }
    `
  );
}

export const Container = styled.article`
  padding: 4rem 0 2rem;
  width: 65vw;
  display: flex;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  ${props => props.showFilterDrawer === null ? "" : props.showFilterDrawer
    ? slideInAnimate() : slideOutAnimate(props.trasnlationDist)}
  @media screen and (max-width: 767px) {
    padding-top: 0;
    width: 100%;
  }
`;

export const Heading = styled.h3`
  text-align: center;
  width: 100%;
  font-size: 40px;
  color: ${props => props.fontColor || '#000000'};

  @media screen and (min-width: 767px) {
    font-size: 40px;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 766px) {
    font-size: 28px;
    margin-bottom: 2rem;
  }
`;

export const Listing = styled.div`
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  box-sizing: border-box;
  margin-bottom: 3rem;

  @media screen and (min-width: 767px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 2rem;
    column-gap: 1.5rem;
    padding: 0 2rem;
  }

  @media screen and (max-width: 766px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 1.5rem;
    column-gap: 1rem;
    padding: 0 1rem;
  }
`;

export const ListingHeading = styled.h2`
  color: #000000;
  font-weight: 600;
  margin-bottom: 0.8rem;

  @media screen and (min-width: 767px) {
    font-size: 28px;
    padding: 0 2rem;
  }

  @media screen and (max-width: 766px) {
    font-size: 20px;
    padding: 0 1rem;
  }
`;

export const AllCategorySection = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  position: sticky;
  bottom: 5%;
  z-index: 5;
  background: transparent;
  width: 100%;
`;

export const AllCategoryButton = styled.div`
  text-align: center;
  border-radius: 30px;
  box-shadow: 0px 16px 36px #0000004a;
  background: ${props => props.backgroundColor || '#ffffff'} 0% 0% no-repeat
    padding-box;
  color: ${props => (props.backgroundColor ? '#ffffff' : '#000000')};
  cursor: pointer;

  @media screen and (min-width: 767px) {
    font-size: 18px;
    padding: 16px 40px;
  }

  @media screen and (max-width: 766px) {
    font-size: 14px;
    padding: 14px 16px;
  }
`;

export const AdContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  background: ${props => props.backgroundColor || '#FFFFFF'};
  padding-top: ${props => props.paddingTop || 0}px;
  padding-bottom: ${props => props.paddingBottom || 0}px;
`;

export const MiddleBodyAdWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
