import store from '../redux/store';
import router from 'next/router';
import { getRoute } from './routes';

export const handleRouteChangesOnClick = (routePath, additionalQuery = {}) => {
  const storeData = store.getState().storeReducer.store;
  router.push({
    pathname: getRoute(routePath, storeData?.store_info?.domain),
    query: additionalQuery,
  });
};
