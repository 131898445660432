export const FlagIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.44"
      height="10.462"
      viewBox="0 0 10.44 10.462"
    >
      <g id="Page-1" transform="translate(0 0.05)">
        <g
          id="_037---Waypoint-Flag"
          data-name="037---Waypoint-Flag"
          transform="translate(0 -0.05)"
        >
          <path
            id="Shape"
            d="M2.644,19.49l.192-.054a.348.348,0,0,0,.241-.429l-2.2-7.87a.435.435,0,0,0-.536-.3l-.025.007a.435.435,0,0,0-.3.536l2.2,7.87a.348.348,0,0,0,.429.241Z"
            transform="translate(0 -9.042)"
          />
          <path
            id="Shape-2"
            data-name="Shape"
            d="M15.693,5.9A8.333,8.333,0,0,1,13.777,4.58a.171.171,0,0,1-.049-.175A24.654,24.654,0,0,0,14.36,1.13c.022-.184-.077-.238-.182-.112C12.128,3.47,8.581.569,6.47,2.8a.719.719,0,0,1,.059.15L7.956,8.056c2.115-2.2,5.649.681,7.7-1.765.1-.126.124-.357.041-.393Z"
            transform="translate(-5.305 -0.95)"
          />
        </g>
      </g>
    </svg>
  );
};
